import React from "react"

import { Container, Content, Copyright, Info, Column, Row } from "./style"

const Footer = () => (
  <Container>
    <Info>
      <Content>
        <Row>
          <Column>
            <a href="https://www.linkedin.com/in/titouanboisbras">
              <h4>LinkedIn</h4>
            </a>
          </Column>
          <Column>
            <a href="https://twitter.com/titouanboisbras">
              <h4>Twitter</h4>
            </a>
          </Column>
          <Column>
            <a href="https://github.com/titouan42">
              <h4>GitHub</h4>
            </a>
          </Column>
          <Column>
            <a href="mailto:contact@titouan.io">
              <h4>Hire me</h4>
            </a>
          </Column>
        </Row>
      </Content>
    </Info>
    <Copyright>
      <Content>
        © 2014 - {new Date().getFullYear()} Titouan Boisbras - All rights
        reserved.
      </Content>
    </Copyright>
  </Container>
)

export default Footer
