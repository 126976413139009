import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

import LogoIcon from "../../assets/logo.svg"
import colors from "../../utils/colors"

export const Container = styled.header`
  background-color: ${colors.blueLight};
`

export const Content = styled.div`
  display: flex;
  text-transform: uppercase;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1.0875rem;
  height: 128px;
  padding: 1.5rem 1.0875rem;
`

export const Brand = styled.div`
  width: 75px;
  height: 75px;
`

export const Link = styled(GatsbyLink)`
  color: ${colors.blueDark};
  text-decoration: none;

  &:hover {
    color: ${colors.blue};
  }
`

export const Logo = styled(LogoIcon)`
  width: auto;
  height: 100%;
  cursor: pointer;
`

export const Navigation = styled.nav`
  justify-content: center;
  display: flex;
  margin-left: auto;
`

export const MenuLinks = styled.ul`
  margin: 0;
  padding: 0;
`

export const MenuLink = styled.li`
  list-style-type: none;
  font-size: 15px;
  letter-spacing: 1px;
  height: 100%;
  margin: 0;
  align-items: center;
  display: flex;
  margin-left: 1.8rem;
`
