import PropTypes from "prop-types"
import React, { Component } from "react"

import colors from "../../utils/colors"

import {
  Brand,
  Container,
  Content,
  Link,
  Logo,
  MenuLink,
  MenuLinks,
  Navigation,
} from "./style"

class Header extends Component {
  render() {
    const { menuLinks } = this.props

    return (
      <Container>
        <Content>
          <Brand>
            <Link aria-label="Logo" to="/">
              <Logo />
            </Link>
          </Brand>
          <Navigation>
            <MenuLinks>
              {menuLinks.map(link => (
                <MenuLink key={link.name}>
                  <Link
                    to={link.link}
                    aria-label={link.name}
                    activeStyle={{ color: colors.blueDark }}
                  >
                    {link.name}
                  </Link>
                </MenuLink>
              ))}
            </MenuLinks>
          </Navigation>
        </Content>
      </Container>
    )
  }
}

Header.propTypes = {
  menuLinks: PropTypes.array.isRequired,
}

Header.defaultProps = {
  menuLinks: [],
}

export default Header
