import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "8rem" }}>404</h1>
      <h2 style={{ textTransform: "uppercase" }}>Page not found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
