export default {
  black: "#222",
  blackLight: "#353535",
  border: "#ccc",
  grey: "#8D96BD",
  blue: "#4B71FF",
  blueDark: "#3D4F9F",
  blueLight: "#F1F5FF",
  white: "#fafafa",
}
