import styled from "styled-components"

import { size } from "../../utils/devices"
import colors from "../../utils/colors"

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const Content = styled.div`
  flex: 1;
  max-width: 960px;
`

export const Info = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${colors.blueLight};
  padding: 1.5rem 1.0875rem;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media (max-width: ${size.mobile}) {
    height: 100%;
    flex-direction: column;
    margin-bottom: 1.8rem;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h4 {
    text-transform: uppercase;
    color: ${colors.blueDark};
    margin: 0.4rem;
  }

  p {
    color: ${colors.blueDark};
    margin: 0.1rem;
  }

  a {
    text-decoration: none;
    color: ${colors.blueDark};
  }
`

export const Copyright = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.white};
  text-align: center;
  background-color: ${colors.blueDark};
  padding: 0.5rem 1.0875rem;
  text-transform: uppercase;
`
